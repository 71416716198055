import { gsap }          from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const tl = gsap.timeline();

function gsaP(el, config) {
	if (!el) return false;
	gsap.registerPlugin(ScrollTrigger);
	gsap.config({
		nullTargetWarn : false,
	});
	return gsap.from(el, config);
}

document.addEventListener('DOMContentLoaded', () => {
	const screen = window.matchMedia('(min-width: 1080px)');
	if (screen.matches) {
		gsaP('.box-item--advantages', {
			scrollTrigger : {
				trigger : '.advantages',
				start   : 'top center',
				end     : '+=300px',
			},
			scale           : 0,
			transformOrigin : 'bottom right',
			stagger         : 1,

		});
		gsaP('.advantages__item', {
			scrollTrigger : {
				trigger : '.advantages__wrapper',
				start   : 'top center',
				end     : '+=500px',
			},
			scale           : 0,
			stagger         : 1,
			duration        : 1,
			ease            : 'back',
			transformOrigin : 'left bottom',
		});

		gsaP('.why__wrap', {
			scrollTrigger : {
				trigger : '.why',
				start   : '-200 center',
				end     : '+=100px',
				// markers : true,
			},
			scale           : 0,
			x               : 100,
			transformOrigin : 'left bottom',
			ease            : 'slow(0.1, 0.1)',
			duration        : 1,
		});

		gsaP('.skill__item', {
			scrollTrigger : {
				trigger : '.skill__list',
				start   : '-300 center',
				scrub   : true,
				// markers : true,
				end     : '+=400px',
			},
			opacity         : 0,
			scale           : 0,
			transformOrigin : 'left right',
			ease            : 'back',
			stagger         : 100,
		});

		gsaP('.programs-banner', {
			scrollTrigger : {
				trigger : '.programs',
				start   : 'top center',
				end     : '+=200',
			},
			duration : 1,
			opacity  : 0,
			scale    : 0,
		});

		gsaP('.programs-list__item', {
			scrollTrigger : {
				trigger : '.programs-list',
				start   : '-300px center',
				end     : '+=100',
			},
			opacity         : 0,
			transformOrigin : 'bottom top',
			ease            : 'cire',
			y               : 150,
			duration        : 0.5,
			stagger         : 0.30,
		});

		gsaP('.programs__item', {
			scrollTrigger : {
				trigger : '.programs__wrap',
				start   : '-300px center',
				end     : '+=400',
			},
			opacity         : 0,
			transformOrigin : 'bottom top',
			ease            : 'cire',
			y               : 150,
			duration        : 0.5,
			stagger         : 0.30,
		});

		gsaP('.steps__item', {
			scrollTrigger : {
				trigger : '.steps',
				start   : '-100px center',
				end     : '+=200',

			},
			opacity         : 0,
			transformOrigin : 'top bottom',
			y               : -100,
			ease            : 'cire',
			duration        : 1,
			stagger         : 0.1,
		});

		gsaP('.skills-list__item', {
			scrollTrigger : {
				trigger : '.skills-list',
				start   : 'top center',
				end     : '+=500px',
			},
			opacity         : 0,
			duration        : 1,
			stagger         : 0.7,
			ease            : 'sine.out',
			xPercent        : -100,
			transformOrigin : 'left bottom',
		});

		// programs-page

		gsaP('.programs-page__group', {
			scrollTrigger : {
				trigger : '.programs-page',
				start   : '500 center',
				end     : '50%',
			},
			opacity         : 0,
			transformOrigin : 'bottom top',
			ease            : 'cire',
			y               : 150,
			duration        : 1,
			stagger         : 0.5,
		});
	}
});
